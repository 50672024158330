import React, { createContext, useContext, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OPTIONS = {
  success: '',
  error: '',
  warning: '',
  info: '',
  dashboardState: {
    refreshRequired: false,
    project: '',
  },
  setProjectRefreshed: '',
  setProjectToRefresh: '',
};

const AlertContext = createContext(OPTIONS);

const INIT_STATE = {
  refreshRequired: false,
  project: '',
};

export const AlertProvider = ({ children }) => {
  const [dashboardState, setDashboardState] = useState(INIT_STATE);
  const success = message => {
    toast.success(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };

  const error = message => {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };

  const info = message => {
    toast.info(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };

  const warning = message => {
    toast.warn(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };

  const setProjectRefreshed = projectName => {
    if (dashboardState.project == projectName) {
      setDashboardState(INIT_STATE);
    }
  };
  const setProjectToRefresh = projectName => {
    setDashboardState({
      project: projectName,
      refreshRequired: true,
    });
  };

  return (
    <AlertContext.Provider
      value={{
        success,
        error,
        info,
        warning,
        dashboardState,
        setProjectRefreshed,
        setProjectToRefresh,
      }}>
      {children}
      <ToastContainer limit={1} />
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  return useContext(AlertContext);
};
