import React, { useEffect, useRef, useState } from 'react';
import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import formatter from '../../utils/formatter';
import Tooltip from '../common/Tooltip';
import ReactLoading from 'react-loading';
import './IssueDetail.css';
import Loader from '../common/Loader';
import StickyHeader from './../common/StickyHeader';
import { useHistory } from 'react-router';
import { useAlert } from '../../context/AlertContext';

const IssueComments = props => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const hiddenFileInput = useRef(null);
  const alert = useAlert();

  useEffect(() => {
    setLoading(true);
    getComments();
  }, []);

  const getComments = async () => {
    try {
      let response = await apiHelper.getComments(props.project, props.id);
      setComments(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const submitComment = async event => {
    event.preventDefault();
    try {
      setLoading(true);
      alert.info('Cargando comentarios');
      history.push('/project/' + props.project);
      await apiHelper.postComment(props.project, props.id, {
        body: newComment,
      });
      setLoading(false);
      alert.success('Comentarios guardados');
      alert.setProjectToRefresh(props.project);
    } catch (error) {
      setLoading(false);
      const statusCode = error.response.status;
      let errorMessage;
      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = error.response.data.error
          ? error.response.data.error.message
          : error.response.data;
      }
      setErrorMessage(errorMessage);
      alert.error('Ocurrió un error al cargar el comentario');
    }
  };

  return (
    <div>
      <h4 className="inline-block text-white">Comentarios</h4>
      {comments.map(comment => {
        if (
          comment.body.toLowerCase().includes('.jpeg') ||
          comment.body.toLowerCase().includes('.jpg') ||
          comment.body.toLowerCase().includes('.png') ||
          comment.body.toLowerCase().includes('.webp')
        ) {
          let urls = comment.body.match(/\bhttps?:\/\/\S+/gi);
          let githubUrls = comment.body.match(
            /(?<=!\[image\]\()\bhttps?:\/\/\S+(?=\))/gi,
          );
          if (githubUrls)
            githubUrls.forEach(url => {
              comment.body = comment.body.replace('![image](' + url + ')', url);
            });
          return (
            <div key={comment.id} className="row justify-content-center mt-3">
              <div className="col-md-12 col-lg-12">
                <span className="text-white">
                  {comment.user.login} -{' '}
                  {formatter.formatDateTime(comment.created_at)}
                </span>
                <textarea
                  type="textarea"
                  name="body"
                  className="form-control"
                  placeholder="No definido"
                  rows={comment.body.length / 80 + 1}
                  value={comment.body}
                  disabled={true}
                  required
                />
                {urls.map((url, index) => (
                  <img key={index} className="image-comment" src={url} />
                ))}
              </div>
            </div>
          );
        } else {
          return (
            <div key={comment.id} className="row justify-content-center mt-3">
              <div className="col-md-12 col-lg-12">
                <span className="text-white">
                  {comment.user.login} -{' '}
                  {formatter.formatDateTime(comment.created_at)}
                </span>
                <textarea
                  type="textarea"
                  name="body"
                  className="form-control"
                  placeholder="No definido"
                  rows={comment.body.length / 80 + 1}
                  value={comment.body}
                  disabled={true}
                  required
                />
              </div>
            </div>
          );
        }
      })}
      {props.disabled && (
        <>
          <div className="row justify-content-center mt-5">
            <div className="col-md-12 col-lg-12">
              <div className="form-group">
                <h4 className="inline-block text-white" htmlFor="body">
                  Nuevo comentario
                </h4>
                <textarea
                  type="textarea"
                  name="body"
                  className="form-control"
                  placeholder="No definido"
                  rows="5"
                  value={newComment}
                  onChange={e => setNewComment(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row mb-1 mt-1 justify-content-end">
            <div className="col-md-12 col-lg-12">
              <button className="btn butt btn-info" onClick={submitComment}>
                <i className="fas fa-paper-plane mr-2" />
                Enviar comentario
              </button>
            </div>
          </div>
        </>
      )}
      {errorMessage}
    </div>
  );
};

export default IssueComments;
