import React from 'react';
import { useEffect, useState, useRef } from 'react';
import apiHelper from '../../utils/apiHelpers';
import formatter from '../../utils/formatter';
import './IssueDetail.css';
import Loader from '../common/Loader';
import StickyHeader from './../common/StickyHeader';
import { BreadCrum } from '../common/BreadCrum';
import IssueComments from './IssueComments';
import SelectIssueType from '../common/selects/SelectIssueType';
import Attachments from '../common/Attachment';
import SelectIssueEnvironment from '../common/selects/SelectIssueEnvironment';
import SelectIssueUrgent from '../common/selects/SelectIssueUrgent';
import Tooltip from '../common/Tooltip';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import SelectIssueAssignDev from '../common/selects/SelectIssueAssignDev';
import { useAlert } from '../../context/AlertContext';

const IssueDetail = props => {
  const [issue, setIssue] = useState({
    id: props.match.params.id,
    title: '',
    created_at: '',
    closed_at: '',
    issueType: '',
    environment: '',
    urgent: '',
    body: '',
    assignees: [],
  });
  const [attachments, setAttachments] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [isNew] = useState(props.match.params.id === 'new');
  const [isDisabled, setDisabled] = useState(true);
  const hiddenFileInput = useRef(null);
  const alertToast = useAlert();

  const [onboarding, setOnboarding] = useState({
    stepsEnabled: apiHelper.getOnboarding('onboardingIssueDetail'),
    initialStep: 0,
    steps: [
      {
        element: '.firstStep',
        intro:
          '<h4>¡Bienvenido a la pantalla de detalle de tarea!</h4><br><p >Vamos a guiarle para crear una nueva tarea, o modificar una existente</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.secondStep',
        intro:
          '<p >En primer lugar, debe indicarse el título de la tarea. Debe ser breve y declarativo (Ej: "Error al intentar iniciar sesión") y deben evitarse títulos demasiado abstractos (Ej: "Error sistema") o demasiado extensos.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.thirdStep',
        intro:
          '<p >En segundo lugar, debe declararse el tipo de tarea: <b>Funcionalidad</b> (un agregado a la funcionalidad del proyecto, como por ejemplo una nueva pantalla), <b>Error</b> (una falla detectada sobre una funcionalidad ya definida) o <b>Soporte</b> (una tarea no vinculada a una funcionalidad del sistema, sino a facilitar su uso. Por ejemplo, la creación de nuevos usuarios).</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.fourthStep',
        intro:
          '<p >Finalmente, debe indicarse una descripción de la tarea. La misma debe ser lo más detallada posible, y en caso de necesidad, pueden incluirse adjuntos de todo tipo (imágenes, archivos de texto, archivos de Excel, etc) para facilitar la comprensión o implementación de la tarea.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.fifthStep',
        intro:
          '<p >Si la tarea fuera de tipo <b>Error</b>, se solicitará indicar el ambiente donde se produce la falla. Esto puede ser en <b>Desarrollo</b> (el entorno de pruebas del sistema) o <b>Producción</b> (el entorno real del sistema). En caso de que la falla sea en Producción, puede indicarse si el error es <b>Urgente</b> (impide el uso del sistema) o <b>No urgente</b> (puede continuar utilizándose).</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.sixthStep',
        intro:
          '<p >Una vez que ha completado toda la información necesaria, puede presionar en <b>Guardar</b> y verá que la tarea se agregará al proyecto actual.</p>',
        tooltipClass: 'onboarding-steps',
      },
      {
        element: '.seventhStep',
        intro:
          '<p >Cuando se crea una nueva tarea en un proyecto, se notifica de forma <b>automática e inmediata</b> al equipo de desarrollo del mismo, lo que asegura un rápido tratamiento de la misma en caso de necesidad. Por esto, es un canal mucho más eficiente que otros medios de comunicación.</p>',
        tooltipClass: 'onboarding-steps',
      },
    ],
  });

  useEffect(() => {
    if (!isNew) {
      setLoading(true);
      apiHelper
        .getIssue(props.match.params.project, props.match.params.id)
        .then(response => {
          let issue = response.data;
          issue.issueType = formatter.getIssueType(issue.labels);
          issue.environment = formatter.getIssueEnvironment(issue.labels);
          issue.urgent = formatter.getIssueUrgent(issue.labels);
          setIssue(issue);
          setDisabled(response.data.disabled);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    } else {
      setLoading(false);
      setDisabled(false);
    }
  }, []);

  const handleChange = event => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    value =
      target.type === 'react-datetime'
        ? formatter.formatDateForAPI(target.value)
        : target.value;
    const name = target.name;
    const newIssue = Object.assign({}, issue);
    newIssue[name] = value;
    // If the modified field is the issue type, and it has "Feature" value, clear "environment" and "urgent" fields
    if (name == 'issueType' && value == 'Feature') {
      newIssue['environment'] = '';
      newIssue['urgent'] = '';
    }
    setIssue(newIssue);
  };

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = event => {
    let file = event.target.files[0];
    if (file) {
      let newAttachments = [];
      newAttachments = newAttachments.concat(attachments);
      if (!attachments.find(elem => elem.name == file.name))
        newAttachments.push(file);
      setAttachments(newAttachments);
    } else {
      setErrorMessage('No se seleccionó ningún archivo');
    }
  };

  const handleRemove = attachment => {
    let newAttachments = [];
    newAttachments = attachments.filter(elem => elem.name != attachment.name);
    setAttachments(newAttachments);
  };

  const submitAttachment = async (id, attachment) => {
    if (!attachment) return;
    try {
      setLoading(true);
      await apiHelper.postAttachment(
        props.match.params.project,
        id,
        attachment,
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      const statusCode = error.response.status;
      let errorMessage;
      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = error.response.data.error
          ? error.response.data.error.message
          : error.response.data;
      }
      setErrorMessage(errorMessage);
    }
  };

  const submitIssue = async event => {
    event.preventDefault();
    try {
      setLoading(true);

      let number;
      //If requests already exists, update it. If not, create it
      props.history.push('/project/' + props.match.params.project);
      if (isNew) {
        alertToast.info('Creando tarea');
        const response = await apiHelper.postIssue(
          props.match.params.project,
          issue,
        );
        number = response.data.number;
        alertToast.success('Tarea creada');
        alertToast.setProjectToRefresh(props.match.params.project);
      } else {
        alertToast.info('Actualizando tarea');
        let patchedIssue = issue;
        patchedIssue.assignees = patchedIssue.assignees.map(i => i.login);
        const response = await apiHelper.patchIssue(
          props.match.params.project,
          props.match.params.id,
          patchedIssue,
        );
        number = response.data.number;
        alertToast.success('Tarea actualizada');
        alertToast.setProjectToRefresh(props.match.params.project);
      }
      await Promise.all(
        attachments.map(async attachment => {
          await submitAttachment(number, attachment);
        }),
      );
      setLoading(false);
      // props.history.push('/project/' + props.match.params.project);
    } catch (error) {
      setLoading(false);
      const statusCode = error?.response?.status;
      let errorMessage;
      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = error?.response?.data?.error
          ? error?.response?.data?.error?.message
          : error?.response?.data;
      }
      if (!errorMessage) {
        setErrorMessage(
          'No se pudo crear su tarea, intente nuevamente más tarde',
        );
      } else {
        setErrorMessage(errorMessage);
      }
      if (isNew) {
        alertToast.error('Ocurrió un error al crear la tarea');
      } else {
        alertToast.error('Ocurrió un error al actualizar la tarea');
      }
    }
  };

  let alert;
  let title;

  if (errorMessage) {
    alert = <div className="alert alert-warning">{errorMessage}</div>;
  }

  if (isNew) {
    title = 'Crear tarea';
  } else {
    title = '#' + issue.number + ' ' + issue.title;
  }

  const onExit = async () => {
    await apiHelper.updateOnboarding({
      onboarding: 'onboardingIssueDetail',
      state: false,
    });
    setOnboarding(prevState => {
      return { ...prevState, stepsEnabled: false };
    });
  };

  return (
    <div className="d-flex flex-column h-100 general">
      <Steps
        enabled={onboarding.stepsEnabled && !loading}
        steps={onboarding.steps}
        initialStep={onboarding.initialStep}
        options={{
          nextLabel: 'Siguiente',
          prevLabel: 'Anterior',
          skipLabel: 'Saltar',
          doneLabel: 'Finalizar',
        }}
        onExit={onExit.bind(this)}
      />
      <StickyHeader titulo={title} icon={'fas fa-wrench icon-vtasks'} />
      <BreadCrum
        className="breadCrum"
        project={props.match.params.project}
        back={'Tareas'}
        actual={title}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="row mb-1 mt-3 justify-content-end">
            <div className="col-md-9">
              {!isNew && issue.milestone && (
                <h4 className="text-white">
                  {issue.milestone.title} - Fecha de entrega{' '}
                  {formatter.formatDate(issue.milestone.due_on)}
                </h4>
              )}
            </div>
            {!isNew && apiHelper.isAdmin() && (
              <div className="col-md-3">
                <a href={issue.html_url} target="blank" className="pull-right">
                  <button className="btn butt btn-info mr-3">
                    <i className="fas fa-external-link-alt mr-1" /> Ver en
                    GitHub
                  </button>
                </a>
              </div>
            )}
          </div>
          <div className="row justify-content-center mt-3">
            <div className="col-md-12 col-lg-12 secondStep">
              <div className="form-group">
                <h4
                  className="inline-block text-white"
                  htmlFor="title"
                  data-tip
                  data-for={'Tooltip-title'}>
                  Título
                </h4>
                <Tooltip
                  id={'Tooltip-title'}
                  tooltipText={
                    'Síntesis de la tarea a realizar. Ej: "Error al exportar el reporte de usuarios"'
                  }
                />

                <input
                  type="text"
                  name="title"
                  maxLength="140"
                  className="form-control"
                  placeholder="No definido"
                  value={issue.title}
                  onChange={handleChange}
                  disabled={isDisabled}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-3">
            <div className="col-md-12 col-lg-12 thirdStep">
              <h4
                className="inline-block text-white"
                htmlFor="issueType"
                data-tip
                data-for={'Tooltip-issueType'}>
                Tipo de tarea
              </h4>
              <Tooltip
                id={'Tooltip-issueType'}
                tooltipText={
                  'Una tarea puede ser una nueva funcionalidad (o una modificación sobre una funcionalidad existente), un error, o una tarea de soporte'
                }
              />
              <SelectIssueType
                name="issueType"
                value={issue.issueType}
                placeholder="Seleccione un tipo de tarea"
                onChange={handleChange}
                required={true}
                disabled={isDisabled}
              />
            </div>
          </div>

          {!isNew && (
            <div className="row justify-content-center mt-3">
              <div className="col-md-12 col-lg-12 thirdStep">
                <h4
                  className="inline-block text-white"
                  htmlFor="issueType"
                  data-tip
                  data-for={'Tooltip-issueType'}>
                  Desarrollador asignado
                </h4>
                {/* <Tooltip
                id={'Tooltip-issueType'}
                tooltipText={
                  'Una tarea puede ser una nueva funcionalidad (o una modificación sobre una funcionalidad existente), un error, o una tarea de soporte'
                }
              /> */}
                <SelectIssueAssignDev
                  name="assignees"
                  value={issue.assignees.length > 0 ? issue.assignees : []}
                  placeholder="Seleccione el desarrollador para asignar la tarea"
                  onChange={handleChange}
                  options={issue.collaborators}
                  required={false}
                  disabled={apiHelper.isClient()}
                />
              </div>
            </div>
          )}

          <div className="row justify-content-center mt-4">
            {!isNew && (
              <div
                className={`col-sm-12 ${
                  issue.closed_at && 'col-lg-6 '
                }secondStep`}>
                <div className="form-group">
                  <h4
                    className="inline-block text-white"
                    htmlFor="created_at"
                    data-tip
                    data-for={'Tooltip-created_at'}>
                    Fecha de creación
                  </h4>
                  <Tooltip
                    id={'Tooltip-created_at'}
                    tooltipText={'Fecha de creación de la tarea'}
                  />
                  <input
                    type="text"
                    name="created_at"
                    maxLength="140"
                    className="form-control"
                    placeholder="No definido"
                    value={new Date(issue.created_at).toLocaleDateString()}
                    onChange={handleChange}
                    disabled
                    required
                  />
                </div>
              </div>
            )}
            {issue.closed_at && (
              <div className="col-sm-12 col-lg-6 secondStep">
                <div className="form-group">
                  <h4
                    className="inline-block text-white"
                    htmlFor="closed_at"
                    data-tip
                    data-for={'Tooltip-closed_at'}>
                    Fecha de cierre
                  </h4>
                  <Tooltip
                    id={'Tooltip-closed_at'}
                    tooltipText={'Fecha de cierre de la tarea'}
                  />
                  <input
                    type="text"
                    name="closed_at"
                    maxLength="140"
                    className="form-control"
                    placeholder="No definido"
                    value={new Date(issue.closed_at).toLocaleDateString()}
                    onChange={handleChange}
                    disabled
                    required
                  />
                </div>
              </div>
            )}
          </div>

          {(issue.issueType == 'Error' || issue.issueType == 'Support') && (
            <div className="row justify-content-center mt-3">
              {issue.environment != 'Production' ? (
                <div className="col-md-12 col-lg-12">
                  <h4
                    className="inline-block text-white"
                    htmlFor="environment"
                    data-tip
                    data-for={'Tooltip-environment'}>
                    Ambiente
                  </h4>
                  <Tooltip
                    id={'Tooltip-environment'}
                    tooltipText={
                      'Una tarea puede estar relacionada al ambiente de desarrollo (Ej: la definición de una nueva funcionalidad) o al ambiente de producción (Ej: un error al querer iniciar sesión en producción)'
                    }
                  />
                  <SelectIssueEnvironment
                    name="environment"
                    value={issue.environment}
                    placeholder="Seleccione el ambiente al que se asocia la tarea"
                    onChange={handleChange}
                    required={true}
                    disabled={isDisabled}
                  />
                </div>
              ) : (
                <>
                  <div className="col-md-6 col-lg-6 fifthStep">
                    <h4
                      className="inline-block text-white"
                      htmlFor="environment"
                      data-tip
                      data-for={'Tooltip-environment'}>
                      Ambiente
                    </h4>
                    <Tooltip
                      id={'Tooltip-environment'}
                      tooltipText={
                        'Una tarea puede estar relacionada al ambiente de desarrollo (Ej: la definición de una nueva funcionalidad) o al ambiente de producción (Ej: un error al querer iniciar sesión en producción)'
                      }
                    />
                    <SelectIssueEnvironment
                      name="environment"
                      value={issue.environment}
                      placeholder="Seleccione el ambiente al que se asocia la tarea"
                      onChange={handleChange}
                      required={true}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <h4
                      className="inline-block text-white"
                      htmlFor="urgent"
                      data-tip
                      data-for={'Tooltip-urgent'}>
                      ¿La tarea requiere tratamiento con urgencia?
                    </h4>
                    <Tooltip
                      id={'Tooltip-urgent'}
                      tooltipText={
                        'Una tarea es urgente si impide o afecta significativamente el uso del sistema. Sólo las tareas en el ambiente de producción pueden ser tratadas como urgentes'
                      }
                    />
                    <SelectIssueUrgent
                      name="urgent"
                      value={issue.urgent}
                      placeholder="Indique si la tarea requiere tratamiento con urgencia"
                      onChange={handleChange}
                      required={true}
                      disabled={isDisabled}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          <div className="row justify-content-center mt-3">
            <div className="col-md-12 col-lg-12 fourthStep">
              <div className="form-group">
                <h4
                  className="inline-block text-white"
                  htmlFor="body"
                  data-tip
                  data-for={'Tooltip-description'}>
                  Descripción
                </h4>
                <Tooltip
                  id={'Tooltip-description'}
                  tooltipText={
                    'Explique la tarea con el mayor grado de detalle posible, incluyendo adjuntos, ejemplos y datos de prueba.'
                  }
                />
                <textarea
                  type="textarea"
                  name="body"
                  className="form-control"
                  placeholder="No definido"
                  rows="5"
                  value={issue.body}
                  onChange={handleChange}
                  disabled={isDisabled}
                  required
                />
              </div>
            </div>
          </div>
          {!isNew && (
            <div className="text-center">
              <IssueComments
                project={props.match.params.project}
                id={props.match.params.id}
                disabled={isDisabled}
              />
            </div>
          )}
          <Attachments attachments={attachments} onRemove={handleRemove} />
          {alert}
          <div className="row mb-1 mt-5 justify-content-end">
            <div className="col-md-12 col-lg-6">
              <button className="btn butt w-100" onClick={handleClick}>
                <i className="fas fa-file-alt mr-2" />
                Adjuntar archivo
              </button>
              <input
                id="file"
                type="file"
                style={{ display: 'none' }}
                ref={hiddenFileInput}
                onChange={handleFileChange}
                className="form-control-file"
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <button
                className="btn butt w-100 sixthStep"
                onClick={submitIssue}>
                <i className="fas fa-save mr-2" />
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IssueDetail;
